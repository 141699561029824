import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateApartment } from "../../data-access";

const DefaultStaysAmountSettingsSchema = z.object({
  defaultMinimumStay: z.coerce.number().min(-1),
  defaultMaximumStay: z.coerce.number().min(-1),
});

type DefaultStaysAmountSettingsSchema = z.infer<
  typeof DefaultStaysAmountSettingsSchema
>;

export const useDefaultStaysAmountSettings = (
  apartmentId: number,
  currentMinimumStay: number,
  currentMaximumStay: number,
) => {
  const { updateApartment, isPending } = useUpdateApartment();

  const form = useForm<DefaultStaysAmountSettingsSchema>({
    resolver: zodResolver(DefaultStaysAmountSettingsSchema),
    defaultValues: {
      defaultMinimumStay: currentMinimumStay,
      defaultMaximumStay: currentMaximumStay,
    },
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit((body) => {
    updateApartment({
      apartmentId,
      body,
    });
  });

  return {
    form,
    onSubmit,
    isPending,
  };
};
