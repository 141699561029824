import {
  FinancialLogCategory,
  FinancialLogCategorySelect,
  Input,
  Label,
  Select,
} from "@twocontinents/dashboard/shared";
import React from "react";

import {
  FinancialLogSourceFilter,
  useFinancialLogsFilters,
} from "./use-financial-logs-filters";

export const FinancialLogsFilters = () => {
  const {
    valueFrom,
    valueTo,
    createdAtFrom,
    createdAtTo,
    updatedAtFrom,
    updatedAtTo,
    settleDateFrom,
    settleDateTo,
    cause,
    id,
    handleInputChange,
    defaultSource,
    handleSourceChange,
    handleCategoryChange,
    category,
  } = useFinancialLogsFilters();
  return (
    <div className={"grid grid-cols-4 gap-4"}>
      <div className={"flex flex-col gap-2"}>
        <Label>Value from</Label>
        <Input
          value={valueFrom}
          onChange={handleInputChange("valueFrom")}
          type={"number"}
        />
      </div>
      <div className={"flex flex-col gap-2"}>
        <Label>Value to</Label>
        <Input
          value={valueTo}
          onChange={handleInputChange("valueTo")}
          type={"number"}
        />
      </div>
      <div className={"flex flex-col gap-2"}>
        <Label>Created at from</Label>
        <Input
          value={createdAtFrom}
          onChange={handleInputChange("createdAtFrom")}
          type={"datetime-local"}
        />
      </div>
      <div className={"flex flex-col gap-2"}>
        <Label>Created at to</Label>
        <Input
          value={createdAtTo}
          onChange={handleInputChange("createdAtTo")}
          type={"datetime-local"}
        />
      </div>

      <div className={"flex flex-col gap-2"}>
        <Label>Updated at from</Label>
        <Input
          value={updatedAtFrom}
          onChange={handleInputChange("updatedAtFrom")}
          type={"datetime-local"}
        />
      </div>

      <div className={"flex flex-col gap-2"}>
        <Label>Updated at to</Label>
        <Input
          value={updatedAtTo}
          onChange={handleInputChange("updatedAtTo")}
          type={"datetime-local"}
        />
      </div>

      <div className={"flex flex-col gap-2"}>
        <Label>Settle date from</Label>
        <Input
          value={settleDateFrom}
          onChange={handleInputChange("settleDateFrom")}
          type={"date"}
        />
      </div>

      <div className={"flex flex-col gap-2"}>
        <Label>Settle date to</Label>
        <Input
          value={settleDateTo}
          onChange={handleInputChange("settleDateTo")}
          type={"date"}
        />
      </div>

      <div className={"flex flex-col gap-2"}>
        <Label>Cause</Label>
        <Input value={cause} onChange={handleInputChange("cause")} />
      </div>

      <div className={"flex flex-col gap-2"}>
        <Label>ID</Label>
        <Input value={id} onChange={handleInputChange("id")} type={"number"} />
      </div>
      <div className={"flex flex-col gap-2"}>
        <Label>Source</Label>
        <Select defaultValue={defaultSource} onValueChange={handleSourceChange}>
          <Select.Trigger className="w-44">
            <Select.Value placeholder="All" />
          </Select.Trigger>
          <Select.Content>
            <Select.Item value={FinancialLogSourceFilter.ALL}>All</Select.Item>
            <Select.Item value={FinancialLogSourceFilter.MANUAL}>
              Manual
            </Select.Item>
            <Select.Item value={FinancialLogSourceFilter.SYSTEM}>
              System
            </Select.Item>
          </Select.Content>
        </Select>
      </div>
      <div className={"flex flex-col gap-2"}>
        <Label>Category</Label>
        <FinancialLogCategorySelect
          categories={Object.values(FinancialLogCategory)}
          onValueChange={handleCategoryChange}
          value={category}
          reset={() => handleCategoryChange(undefined)}
        />
      </div>
    </div>
  );
};
