import { zodResolver } from "@hookform/resolvers/zod";
import { InvoiceStatus } from "@twocontinents/dashboard/travel-agencies/invoices/shared";
import { CurrencyCode } from "@twocontinents/shared";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useCreateInvoice } from "../data-access";

const CreateInvoiceFormSchema = z.object({
  currency: z.nativeEnum(CurrencyCode),
  issueDate: z.string(),
  sellDate: z.string(),
  paymentDeadline: z.string(),
  travelAgencyId: z.coerce.number(),
  status: z.nativeEnum(InvoiceStatus),
  amount: z.coerce.number().min(0),
  providerNumber: z.string(),
});

type CreateInvoiceFormValues = z.infer<typeof CreateInvoiceFormSchema>;

export const useCreateInvoiceForm = () => {
  const form = useForm<CreateInvoiceFormValues>({
    resolver: zodResolver(CreateInvoiceFormSchema),
    defaultValues: {
      currency: CurrencyCode.AED,
    },
  });

  const [file, setFile] = useState<File | null>(null);

  const {
    formState: { isValid },
  } = form;

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }
    setFile(files[0]);
  };

  const { createInvoice, isPending } = useCreateInvoice();

  const handleCreateInvoiceFormSubmit = form.handleSubmit((data) => {
    if (!file) {
      return;
    }
    const { travelAgencyId, ...body } = data;
    createInvoice({ body, agencyId: travelAgencyId, file });
  });

  return {
    form,
    handleCreateInvoiceFormSubmit,
    isPending,
    handleFileInputChange,
    isFormValid: isValid,
  };
};
