import { DateFormatter } from "@twocontinents/shared";

import { BookingOrder } from "../types";

export const useBookingOrderDetails = (order: BookingOrder) => {
  const formattedPurchasedTime = DateFormatter.formatToDDMMYYYYHHmm(
    order.timePurchased,
  );

  const orderDetailsRows = [
    {
      label: "Order ID",
      content: order.id,
    },
    {
      label: "Apartment name",
      content: order.apartmentDescription,
    },
    {
      label: "Apartment internal number",
      content: order.apartmentInternalNumber,
    },
    {
      label: "Purchased time",
      content: formattedPurchasedTime,
    },
    {
      label: "Order status",
      content: order.status,
    },
    {
      label: "Total price",
      content: order.totalPrice.toFixed(2),
    },
    {
      label: "Currency",
      content: order.currency,
    },
    {
      label: "Check-in",
      content: order.dateFrom,
    },
    {
      label: "Check-out",
      content: order.dateTo,
    },
  ];

  return { formattedPurchasedTime, orderDetailsRows };
};
