import {
  DetailsTable,
  OrderStatusesSwitches,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { BookingOrder } from "../types";
import { useBookingOrderDetails } from "./use-booking-order-details";

interface BookingOrderDetailsProps {
  order: BookingOrder;
}

export const BookingOrderDetails = ({ order }: BookingOrderDetailsProps) => {
  const { formattedPurchasedTime, orderDetailsRows } =
    useBookingOrderDetails(order);
  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>
          Order #{order.id} from {formattedPurchasedTime}
        </SettingCard.Title>
        <SettingCard.Description>
          Review order details and change its statuses.
        </SettingCard.Description>
      </SettingCard.Header>

      <DetailsTable>
        {orderDetailsRows.map((row) => (
          <DetailsTable.Row key={row.label} {...row} />
        ))}
      </DetailsTable>

      <OrderStatusesSwitches order={order} />
    </SettingCard>
  );
};
