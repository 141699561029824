import {
  Table,
  buttonVariants,
  cn,
  routes,
} from "@twocontinents/dashboard/shared";
import { Apartment } from "@twocontinents/dashboard/stays/apartments/shared";
import { Link } from "react-router-dom";

interface ApartmentsTableProps {
  apartments: Apartment[];
}

export const ApartmentsTable = ({ apartments }: ApartmentsTableProps) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.Head className="w-36">ID</Table.Head>
          <Table.Head>Name</Table.Head>
          <Table.Head>Status</Table.Head>
          <Table.Head className="text-center">Action</Table.Head>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {apartments.length > 0 &&
          apartments.map((apartment) => (
            <Table.Row key={apartment.id}>
              <Table.Cell>{apartment.id}</Table.Cell>
              <Table.Cell>{apartment.description}</Table.Cell>
              <Table.Cell>
                <div className="flex items-center">
                  <div
                    className={cn(`mr-2 h-2.5 w-2.5 rounded-full`, {
                      "bg-green-500": apartment.active,
                      "bg-red-500": !apartment.active,
                    })}
                  />
                  {apartment.active ? "Active" : "Inactive"}
                </div>
              </Table.Cell>
              <Table.Cell className="flex flex-col justify-center gap-4">
                <Link
                  to={`${routes.stays.apartments.show.url(apartment.id)}`}
                  className={`${buttonVariants({ variant: "outline" })} w-full flex justify-center`}
                >
                  Manage
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};
