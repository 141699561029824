import {
  OrderStatus,
  SortDirection,
  usePaginationQueryParams,
} from "@twocontinents/dashboard/shared";
import { ApiLocale } from "@twocontinents/shared";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

export const useBookingOrderFiltersQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    bookingId: withDefault(NumberParam, undefined),
    status: withDefault(createEnumParam(Object.values(OrderStatus)), undefined),
    sortBy: withDefault(StringParam, "timePurchased"),
    sortDirection: withDefault(
      createEnumParam(Object.values(SortDirection)),
      SortDirection.DESC,
    ),
    customerName: withDefault(StringParam, undefined),
    customerSurname: withDefault(StringParam, undefined),
    customerEmail: withDefault(StringParam, undefined),
    dateFrom: withDefault(StringParam, undefined),
    dateTo: withDefault(StringParam, undefined),
    locale: withDefault(createEnumParam(Object.values(ApiLocale)), undefined),
    apartmentId: withDefault(NumberParam, undefined),
    paymentConfirmed: withDefault(BooleanParam, undefined),
  });

  const { page, size } = usePaginationQueryParams();

  return {
    query: {
      ...query,
      page,
      size,
    },
    setQuery,
  };
};
