import {
  ALL_ITEMS_ID,
  AttractionOption,
  NOT_SELECTED_ID,
  useAttractionGroups,
  useGetAttraction,
  useGetAttractions,
  useGetSupervisors,
} from "@twocontinents/dashboard/shared";
import { ApiLocale, DateFormatter } from "@twocontinents/shared";
import { useFormContext } from "react-hook-form";

import { StatisticsTab } from "../../../../types";
import { StatisticsFormSchema } from "../../use-statistics";

export const useStatisticsFilter = () => {
  const { attractions } = useGetAttractions();
  const { setValue, watch } = useFormContext<StatisticsFormSchema>();

  const attractionId = watch("attractionId") ?? NOT_SELECTED_ID;
  const attractionGroupId = watch("attractionGroupId") ?? NOT_SELECTED_ID;
  const timePurchasedFrom = watch("timePurchasedFrom");
  const timePurchasedFromDate = timePurchasedFrom
    ? DateFormatter.formatToNativeDate(timePurchasedFrom)
    : undefined;
  const timePurchasedTo = watch("timePurchasedTo");
  const timePurchasedToDate = timePurchasedTo
    ? DateFormatter.formatToNativeDate(timePurchasedTo)
    : undefined;
  const tab = watch("tab");
  const supervisorId = watch("supervisorId");

  const { attraction: selectedAttraction } = useGetAttraction(attractionId);

  const { supervisors } = useGetSupervisors();

  const { selectedGroup } = useAttractionGroups({
    attractionGroups: selectedAttraction?.attractionGroups ?? [],
    groupId: attractionGroupId,
  });

  const selectedAttractionGroups = selectedAttraction?.attractionGroups ?? [];

  const onAttractionSelect = (attraction: AttractionOption) => {
    setValue("attractionId", attraction.id);
    setValue("attractionGroupId", undefined);
    setValue("attractionVariantId", undefined);
  };

  const onAttractionGroupSelect = (id: number) => {
    setValue("attractionGroupId", id);
    setValue("attractionVariantId", undefined);
  };
  const onAttractionVariantSelect = (id: number) => {
    setValue("attractionVariantId", id);
  };

  const onTimePurchasedFromChange = (date: Date | undefined) => {
    if (date) {
      setValue("timePurchasedFrom", DateFormatter.formatToYYYYMMDD(date));
    }
  };
  const onTimePurchasedToChange = (date: Date | undefined) => {
    if (date) {
      setValue("timePurchasedTo", DateFormatter.formatToYYYYMMDD(date));
    }
  };

  const onLocaleChange = (locale: ApiLocale | undefined) => {
    setValue("locale", locale);
  };

  const onSupervisorChange = (supervisorId?: string) => {
    if (supervisorId) {
      setValue("supervisorId", Number.parseInt(supervisorId));
    }
  };

  const areAllAttractionsSelected = attractionId === ALL_ITEMS_ID;
  const isCurrentTabRange = tab === StatisticsTab.Range;

  return {
    attractions,
    attractionId,
    onAttractionSelect,
    areAllAttractionsSelected,
    selectedAttraction,
    selectedAttractionGroups,
    onAttractionGroupSelect,
    selectedGroup,
    onAttractionVariantSelect,
    attractionGroupId,
    isCurrentTabRange,
    timePurchasedFromDate,
    timePurchasedToDate,
    onTimePurchasedFromChange,
    onTimePurchasedToChange,
    onLocaleChange,
    supervisors,
    onSupervisorChange,
    supervisorId: supervisorId ? supervisorId.toString() : undefined,
  };
};
