// TODO: investigate if can generate that enum automatically from open api

export enum ApplicationErrorCode {
  GENERIC_CLIENT_ERROR = 100,
  EXCHANGE_RATE_CHANGED = 101,
  COUPON_EXPIRED = 102,
  COUPON_INSUFFICIENT_USAGES = 103,
  COUPON_INVALID_RESERVATIONS = 104,
  RESERVATION_NOT_ENOUGH_SLOTS = 105,
  RESERVATION_DATETIME_INVALID = 106,
  RESERVATION_TOTAL_PARTICIPANTS_INVALID = 107,
  TOUR_NOT_ENOUGH_PLACES = 108,
  TOUR_FEATURE_NOT_AVAILABLE = 109,
  PICKUP_LOCATION_NOT_AVAILABLE = 110,
  TOUR_NEW_MAX_PARTICIPANTS_TOO_SMALL = 111,
  TOUR_IS_NOT_PLANNED = 112,
  GUIDE_AVAILABILITY_NOT_ENABLED = 113,
  PROVIDED_GREATER_THAN_ONE_AMOUNT_OF_SINGLE_EXTRA_OPTION = 114,
  APARTMENT_NOT_AVAILABLE = 115,
  APARTMENT_ACCOMMODATIONS_AMOUNT_NOT_VALID = 116,
  APARTMENT_PEOPLE_AMOUNT_TOO_HIGH = 117,
  ATTRACTION_INACTIVE = 118,
  TOUR_TIME_REQUIRED = 119,
  NATIVE_DRIVER_AVAILABILITY_NOT_ENABLED = 120,
  PRIVATE_GUIDE_NOT_AVAILABLE = 121,
  NATIVE_DRIVER_NOT_AVAILABLE = 122,
  EXTRA_OPTION_NOT_AVAILABLE = 123,
  RESERVATION_INVALID_ADDITIONAL_DETAILS_FORMAT = 124,
  ATTRACTION_VARIANT_NOT_AVAILABLE = 125,
  AVAILABILITY_EXTERNAL_ENABLED = 126,
  DATA_VALIDATION_ERROR = 127,
  CURRENCY_NOT_SUPPORTED = 128,
  EXTRA_OPTIONS_FROM_SAME_GROUP = 129,
  INVALID_GEOLOCATION = 130,
  TOUR_PARTICIPANTS_NOT_PROVIDED = 131,
  TOUR_PARTICIPANTS_BELONG_TO_DIFFERENT_TOURS = 132,
  CANNOT_UPDATE_TOUR_TERM = 133,
  UNAUTHORIZED = 134,
  FORBIDDEN = 135,
  COUPON_ALREADY_EXISTS = 136,
  COUPON_NOT_FOUND = 137,
  TRAVEL_AGENCY_INSUFFICIENT_BALANCE = 138,
  INVALID_COMMISSION_VALUE = 139,
  COUPON_NOT_APPLICABLE_FOR_TRAVEL_AGENCY = 140,
  USER_WITH_GIVEN_LOGIN_ALREADY_EXISTS = 141,
  TRAVEL_AGENCY_CURRENCY_NOT_SUPPORTED = 142,
  OBSOLETE_EXTRA_OPTION = 143,
  OBSOLETE_AGE_GROUP = 144,
  TICKET_ALREADY_EXISTS = 145,
  REQUESTED_TICKETS_ARE_NOT_UNIQUE = 146,
  NOT_ENOUGH_TICKETS_AVAILABLE = 147,
  AGENT_INSUFFICIENT_BALANCE = 148,
  GENERIC_SERVER_ERROR = 500,
  CAPTCHA_VERIFICATION_FAILED = 1,
}
