import {
  FinancialLogCategory,
  usePaginationQueryParams,
} from "@twocontinents/dashboard/shared";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { GetFinancialLogsFilters } from "../types";
import { FinancialLogSource } from "../types/financial-log-source";

export const useFinancialLogsQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    valueFrom: withDefault(NumberParam, undefined),
    valueTo: withDefault(NumberParam, undefined),
    settled: withDefault(BooleanParam, false),
    source: withDefault(
      createEnumParam(Object.values(FinancialLogSource)),
      undefined,
    ),
    category: withDefault(
      createEnumParam(Object.values(FinancialLogCategory)),
      undefined,
    ),
    createdAtFrom: withDefault(StringParam, ""),
    createdAtTo: withDefault(StringParam, ""),
    updatedAtFrom: withDefault(StringParam, ""),
    updatedAtTo: withDefault(StringParam, ""),
    settleDateFrom: withDefault(StringParam, ""),
    settleDateTo: withDefault(StringParam, ""),
    cause: withDefault(StringParam, ""),
    referredOrderId: withDefault(NumberParam, undefined),
    id: withDefault(NumberParam, undefined),
    reservationIdFormValue: withDefault(NumberParam, undefined),
    orderIdFormValue: withDefault(NumberParam, undefined),
  });

  const { page, size } = usePaginationQueryParams();

  return {
    query: {
      ...query,
      page,
      size,
    },
    setQuery,
  };
};

export type FinancialLogsQueryParams = ReturnType<
  typeof useFinancialLogsQueryParams
>["query"] &
  GetFinancialLogsFilters;
