import { AttractionOrder } from "@twocontinents/dashboard/attractions/shared";
import { DateFormatter } from "@twocontinents/shared";

export const useOrderDetails = (order: AttractionOrder) => {
  const formattedPurchasedTime = DateFormatter.formatToDDMMYYYYHHmm(
    order.timePurchased,
  );

  const isMultiReservation = order.reservations.length > 1;

  return {
    formattedPurchasedTime,
    isMultiReservation,
  };
};
