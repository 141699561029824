import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

import { useInvalidateGetApartment } from "./get-apartment";

type Body = components["schemas"]["UpdateApartmentRequest"];

interface Props {
  apartmentId: number;
  body: Body;
}

export const useUpdateApartment = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetApartment();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ apartmentId, body }: Props) =>
      apiClient
        .PATCH(`/private/stays/apartments/{id}`, {
          params: {
            path: { id: apartmentId },
          },
          body,
        })
        .then((response) => response.data),

    onSuccess: async (_, { apartmentId }) => {
      toast.success("Apartment has been updated successfully.");
      await invalidate(apartmentId);
    },
    onError: () => {
      toast.error("Apartment could not be updated");
    },
  });

  return { updateApartment: mutate, isPending };
};
