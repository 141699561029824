import { EmptyState, Pagination } from "@twocontinents/dashboard/shared";
import { ShoppingCartIcon } from "lucide-react";

import { BookingOrdersTable } from "./booking-orders-table";
import { FilterBar } from "./filter-bar";
import { useBookingsTab } from "./use-bookings-tab";

export const BookingsTab = () => {
  const { isLoading, paginatedOrders } = useBookingsTab();

  const showPagination = !isLoading && paginatedOrders;
  const showOrdersTable = paginatedOrders && !isLoading;
  const showOrdersNotFound =
    (paginatedOrders?.orders?.length ?? 0) === 0 && !isLoading;

  return (
    <div>
      <FilterBar />
      {showOrdersTable && (
        <BookingOrdersTable orders={paginatedOrders.orders} />
      )}
      {showOrdersNotFound && (
        <EmptyState>
          <EmptyState.Icon>
            <ShoppingCartIcon size={40} />
          </EmptyState.Icon>
          <EmptyState.Header>Orders not found</EmptyState.Header>
          <EmptyState.Paragraph>
            Check if filters are valid or try again later
          </EmptyState.Paragraph>
        </EmptyState>
      )}
      {showPagination && (
        <Pagination
          totalPages={paginatedOrders.pagination.totalPages}
          sizeLabel="Orders per page"
        />
      )}
    </div>
  );
};
