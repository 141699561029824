import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ALL_ITEMS_ID,
  NOT_SELECTED_ID,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";

const queryKey = (apartmentId: number) => ["private-apartment", apartmentId];

export const useGetApartment = (apartmentId: number) => {
  const apiClient = usePrivateApiClient();
  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET(`/private/stays/apartments/{id}`, {
          params: {
            path: { id: apartmentId },
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(apartmentId),
    enabled: apartmentId !== NOT_SELECTED_ID && apartmentId !== ALL_ITEMS_ID,
  });

  return { apartment: data, isLoading };
};

export const useInvalidateGetApartment = () => {
  const queryClient = useQueryClient();

  const invalidate = async (apartmentId: number) => {
    await queryClient.invalidateQueries({ queryKey: queryKey(apartmentId) });
  };

  return { invalidate };
};
