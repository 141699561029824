import { EmptyState } from "@twocontinents/dashboard/shared";
import { FerrisWheel } from "lucide-react";
import { FormProvider } from "react-hook-form";

import { ApartmentsFilters } from "./apartments-filters";
import { ApartmentsTable } from "./apartments-table";
import { useApartmentsListing } from "./use-apartments-listing";

export const ApartmentsListing = () => {
  const { apartments, form } = useApartmentsListing();
  return (
    <>
      <FormProvider {...form}>
        <ApartmentsFilters />
      </FormProvider>
      {apartments && <ApartmentsTable apartments={apartments} />}
      {apartments.length === 0 && (
        <EmptyState>
          <EmptyState.Icon>
            <FerrisWheel size={40} />
          </EmptyState.Icon>
          <EmptyState.Header>Apartments not found</EmptyState.Header>
          <EmptyState.Paragraph>
            There are no apartments that match your search criteria
          </EmptyState.Paragraph>
        </EmptyState>
      )}
    </>
  );
};
