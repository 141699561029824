import { useQuery } from "@tanstack/react-query";

import { usePrivateApiClient } from "../utils";

const queryKey = () => ["supervisors"];

export const useGetSupervisors = () => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/partners/travel-agencies/supervisors", {})
        .then((response) => response.data),
    queryKey: queryKey(),
  });

  return {
    supervisors: data?.supervisors ?? [],
    isLoading,
  };
};
