import { useQuery } from "@tanstack/react-query";
import {
  orderQueryKey,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";

export const useGetBookingOrder = (id: number) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/orders/booking/{id}", {
          params: {
            path: {
              id,
            },
          },
        })
        .then((response) => response.data),
    queryKey: orderQueryKey(id),
    refetchOnWindowFocus: true,
  });

  return {
    order: data,
    isLoading,
  };
};
