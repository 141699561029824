import { ALL_ITEMS_ID } from "../constants";
import { Supervisor } from "../types";
import { cn } from "../utils";
import { Select } from "./shadcn";

interface SupervisorSelectProps {
  supervisors: Supervisor[];
  onValueChange: (id?: string) => void;
  defaultOpen?: boolean;
  value?: string;
  allItemsOption?: boolean;
  className?: string;
}

export const SupervisorSelect = ({
  onValueChange,
  supervisors,
  defaultOpen,
  value,
  className,
  allItemsOption = false,
}: SupervisorSelectProps) => {
  return (
    <Select
      value={value?.toString()}
      defaultValue={allItemsOption ? ALL_ITEMS_ID.toString() : undefined}
      onValueChange={(value) => onValueChange(value)}
      defaultOpen={defaultOpen}
    >
      <Select.Trigger className={cn("max-w-md h-max min-h-10", className)}>
        <Select.Value placeholder="Choose Supervisor" />
      </Select.Trigger>
      <Select.Content>
        {allItemsOption && (
          <Select.Item value="-1">All Supervisors</Select.Item>
        )}

        {supervisors.map((supervisor) => (
          <Select.Item value={supervisor.id.toString()} key={supervisor.id}>
            {supervisor.name}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  );
};
