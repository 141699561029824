import {
  ActivityFilter,
  Form,
  FormItem,
  Input,
  Select,
} from "@twocontinents/dashboard/shared";
import { useFormContext } from "react-hook-form";

import { ApartmentsFiltersFormSchema } from "./use-apartments-listing";

export const ApartmentsFilters = () => {
  const form = useFormContext<ApartmentsFiltersFormSchema>();

  return (
    <Form {...form}>
      <form className="flex gap-4 my-4">
        <Form.Field
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormItem.Row>
                <FormItem.Label>Name</FormItem.Label>
              </FormItem.Row>
              <FormItem.Row>
                <FormItem.Control>
                  <Input {...field} placeholder="Princess tower" />
                </FormItem.Control>{" "}
              </FormItem.Row>
              <FormItem.Message />
            </FormItem>
          )}
        />
        <Form.Field
          control={form.control}
          name="active"
          render={({ field }) => (
            <FormItem>
              <FormItem.Row>
                <FormItem.Label>Activity</FormItem.Label>
              </FormItem.Row>
              <FormItem.Row>
                <FormItem.Control>
                  <Select
                    defaultValue={ActivityFilter.ALL}
                    onValueChange={field.onChange}
                  >
                    <Select.Trigger className="max-w-md h-max min-h-10 min-w-44">
                      <Select.Value placeholder="Choose activity" />
                    </Select.Trigger>
                    <Select.Content>
                      <Select.Item value={ActivityFilter.ALL}>All</Select.Item>
                      <Select.Item value={ActivityFilter.ACTIVE}>
                        Active
                      </Select.Item>
                      <Select.Item value={ActivityFilter.INACTIVE}>
                        Inactive
                      </Select.Item>
                    </Select.Content>
                  </Select>
                </FormItem.Control>{" "}
              </FormItem.Row>
              <FormItem.Message />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
};
