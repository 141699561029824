import {
  ApiLocaleSelect,
  AttractionCombobox,
  AttractionGroupSelect,
  AttractionVariantSelect,
  DateRangePicker,
  SupervisorSelect,
  Tooltip,
} from "@twocontinents/dashboard/shared";

import { useStatisticsFilter } from "./use-statistics-filter";

export const StatisticsFilter = () => {
  const {
    attractions,
    attractionId,
    onAttractionSelect,
    areAllAttractionsSelected,
    selectedAttraction,
    selectedAttractionGroups,
    onAttractionGroupSelect,
    selectedGroup,
    onAttractionVariantSelect,
    attractionGroupId,
    isCurrentTabRange,
    timePurchasedFromDate,
    timePurchasedToDate,
    onTimePurchasedFromChange,
    onTimePurchasedToChange,
    onLocaleChange,
    supervisors,
    onSupervisorChange,
    supervisorId,
  } = useStatisticsFilter();

  return (
    <div className={"grid grid-cols-3 gap-4 mt-2"}>
      <AttractionCombobox
        attractions={attractions}
        selectedId={attractionId}
        setSelectedOption={onAttractionSelect}
      />

      {!areAllAttractionsSelected && (
        <>
          {selectedAttraction && (
            <AttractionGroupSelect
              attractionGroups={selectedAttractionGroups}
              onValueChange={onAttractionGroupSelect}
              defaultOpen
              key={attractionId}
            />
          )}

          {selectedGroup && (
            <AttractionVariantSelect
              attractionVariants={selectedGroup.attractionVariants}
              onValueChange={onAttractionVariantSelect}
              defaultOpen
              className={"max-w-xs"}
              key={attractionGroupId}
            />
          )}
        </>
      )}

      {isCurrentTabRange && (
        <DateRangePicker
          dateFrom={timePurchasedFromDate}
          dateTo={timePurchasedToDate}
          setDateFrom={onTimePurchasedFromChange}
          setDateTo={onTimePurchasedToChange}
        />
      )}
      <Tooltip.Provider>
        <Tooltip>
          <Tooltip.Trigger>
            <ApiLocaleSelect
              onValueChange={onLocaleChange}
              resetLabel="All"
              reset={() => onLocaleChange(undefined)}
            />
          </Tooltip.Trigger>
          <Tooltip.Content>
            If locale filter is specified, only financial logs created after
            15.11.2024 will be considered.
          </Tooltip.Content>
        </Tooltip>
      </Tooltip.Provider>
      <SupervisorSelect
        value={supervisorId}
        supervisors={supervisors}
        onValueChange={onSupervisorChange}
        allItemsOption
      />
    </div>
  );
};
