import { AttractionOrder } from "@twocontinents/dashboard/attractions/shared";
import {
  OrderStatusesSwitches,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { MultiReservationsOrderDetails } from "./multi-reservations-order-details";
import { SingleReservationOrderDetails } from "./single-reservation-order-details";
import { useOrderDetails } from "./use-order-details";

interface OrderDetailsProps {
  order: AttractionOrder;
}

export const OrderDetails = ({ order }: OrderDetailsProps) => {
  const { formattedPurchasedTime, isMultiReservation } = useOrderDetails(order);

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>
          Order #{order.id} from {formattedPurchasedTime}
        </SettingCard.Title>
        <SettingCard.Description>
          Review order details and change its statuses.
        </SettingCard.Description>
      </SettingCard.Header>
      {isMultiReservation ? (
        <MultiReservationsOrderDetails order={order} />
      ) : (
        <SingleReservationOrderDetails order={order} />
      )}
      <OrderStatusesSwitches order={order} />
    </SettingCard>
  );
};
