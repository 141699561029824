import {
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { useDescriptionSettings } from "./use-description-settings";

interface DescriptionSettingsProps {
  apartmentId: number;
  defaultDescription: string;
}

export const DescriptionSettings = ({
  apartmentId,
  defaultDescription,
}: DescriptionSettingsProps) => {
  const { form, onSubmit, isPending } = useDescriptionSettings(
    apartmentId,
    defaultDescription,
  );

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Description settings</SettingCard.Title>
        <SettingCard.Description>
          Change apartment description
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Field
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label>Description</FormItem.Label>
                  <FormItem.Control>
                    <Input {...field} />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />

          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will change apartment description only on the
              dashboard.
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
