import { ChangeEvent } from "react";

import { useBookingOrderFiltersQueryParams } from "../../hooks";

export const useFilterBar = () => {
  const { query, setQuery } = useBookingOrderFiltersQueryParams();

  const handleTextChange =
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setQuery({ [key]: e.target.value });
    };

  return {
    query,
    handleTextChange,
    setQuery,
  };
};
