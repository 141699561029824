import { DateFormatter } from "@twocontinents/shared";
import { Link } from "react-router-dom";

import { useAuth } from "../../auth";
import { cn, routes } from "../../utils";

export const Navbar = () => {
  const { user } = useAuth();
  const isExpired = user?.isExpired ?? false;
  const timeToExpire = user?.timeToExpire ?? 0;
  const formattedTimeToExpire = DateFormatter.formatTimeToHM(timeToExpire);

  return (
    <nav className="border-b border-b-slate-800">
      <div className="container mx-auto flex justify-between py-4 2xl:px-24 3xl:px-52 items-center">
        <div className="flex items-center gap-6">
          <Link
            to={routes.home}
            className="relative mr-4 aspect-[202/85] h-16 flex items-center"
          >
            <img
              src="/twc-dark.svg"
              sizes="10vw"
              alt="TWC logo"
              loading="lazy"
            />
          </Link>
        </div>
        <p className="text-sm">
          Session expires in:{" "}
          <span
            title="Session expires in"
            className={cn(
              "font-semibold",
              isExpired ? "text-red-500" : "text-green-500",
            )}
          >
            {isExpired ? "Session Expired" : "" + formattedTimeToExpire}
          </span>
        </p>
      </div>
    </nav>
  );
};
