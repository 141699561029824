import { GenderSelect } from "@twocontinents/dashboard/attractions/shared";
import {
  AttractionCombobox,
  AttractionVariantSelect,
  Button,
  DatePicker,
  Form,
  FormItem,
  Input,
  NOT_SELECTED_ID,
  SettingCard,
} from "@twocontinents/dashboard/shared";
import { Link } from "react-router-dom";

import { useGenerateTicketForm } from "./use-generate-ticket-form";

export const GenerateTicketForm = () => {
  const {
    form,
    handleFormSubmit,
    ticketUrl,
    isPending,
    ageGroups,
    attractions,
    variants,
  } = useGenerateTicketForm();

  return (
    <Form {...form}>
      <form onSubmit={handleFormSubmit} autoComplete="off">
        <Form.Fields>
          <Form.Field
            control={form.control}
            name="date"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-32">
                    Ticket date
                  </FormItem.Label>
                  <FormItem.Control>
                    <DatePicker
                      date={field.value ?? undefined}
                      setDate={field.onChange}
                      classNames={{
                        trigger: "w-72",
                      }}
                      disabled={{ before: new Date() }}
                    />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="customerDetails.customerName"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-32">
                    Customer Name
                  </FormItem.Label>
                  <FormItem.Control>
                    <Input {...field} className="max-w-md" />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />

          <Form.Field
            control={form.control}
            name="customerDetails.customerSurname"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-32">
                    Customer Surname
                  </FormItem.Label>
                  <FormItem.Control>
                    <Input {...field} className="max-w-md" />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="customerDetails.customerGender"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-32">
                    Customer gender
                  </FormItem.Label>
                  <FormItem.Control>
                    <GenderSelect
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="attractionId"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-32">
                    Attraction
                  </FormItem.Label>
                  <FormItem.Control>
                    <AttractionCombobox
                      attractions={attractions}
                      setSelectedOption={(option) => {
                        field.onChange(option?.id ?? NOT_SELECTED_ID);
                      }}
                      selectedId={field.value}
                    />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="variantId"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-32">Variant</FormItem.Label>
                  <FormItem.Control>
                    <AttractionVariantSelect
                      attractionVariants={variants}
                      onValueChange={field.onChange}
                      value={field.value}
                    />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          {ageGroups.map((ageGroup, i) => (
            <>
              <Form.Field
                control={form.control}
                name={`ageGroups.${i}.id`}
                render={({ field }) => (
                  <FormItem className="hidden">
                    <FormItem.Control>
                      <Input {...field} value={ageGroup.id} type="number" />
                    </FormItem.Control>
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name={`ageGroups.${i}.amount`}
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label className="min-w-32">
                        {ageGroup.description}
                      </FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} type="number" className="max-w-md" />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
            </>
          ))}
        </Form.Fields>
        {ticketUrl && (
          <div className="my-4 flex gap-8 items-center">
            <Button variant="outline" type="button">
              <Link to={ticketUrl} target="_blank">
                Open ticket
              </Link>
            </Button>
          </div>
        )}
        <SettingCard.Footer>
          <SettingCard.Description>
            After filling the form, click the button to generate ticket link
          </SettingCard.Description>
          <SettingCard.Button type="submit" isLoading={isPending}>
            Generate
          </SettingCard.Button>
        </SettingCard.Footer>
      </form>
    </Form>
  );
};
