import { useQuery } from "@tanstack/react-query";
import {
  ALL_ITEMS_ID,
  NOT_SELECTED_ID,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";

import { AttractionOrdersStatisticsFilter } from "../types";

const queryKey = (filter?: AttractionOrdersStatisticsFilter) => [
  "statistics",
  filter,
];

export const useGetAttractionOrdersStatistics = (
  filter?: AttractionOrdersStatisticsFilter,
) => {
  const apiClient = usePrivateApiClient();
  const query = {
    ...filter,
    attractionId:
      filter?.attractionId === ALL_ITEMS_ID ? undefined : filter?.attractionId,
    supervisorId:
      filter?.supervisorId === ALL_ITEMS_ID ? undefined : filter?.supervisorId,
  };

  console.log(query);

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/orders/attraction/statistics", {
          params: {
            query,
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(filter),
    enabled: Boolean(filter?.attractionId !== NOT_SELECTED_ID),
  });

  return { statistics: data, isLoading };
};
