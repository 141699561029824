import {
  DetailRowProps,
  DetailsTable,
  routes,
} from "@twocontinents/dashboard/shared";
import { TravelAgency } from "@twocontinents/dashboard/travel-agencies/travel-agency/shared";
import { Link } from "react-router-dom";

import { AddTravelAgencyBalanceModal } from "./add-travel-agency-balance-modal";
import { UpdateTravelAgencyModal } from "./update-travel-agency-modal";

interface TravelAgencyTabProps {
  travelAgency: TravelAgency;
}

export const TravelAgencyTab = ({ travelAgency }: TravelAgencyTabProps) => {
  const details: DetailRowProps[] = [
    { label: "ID", content: travelAgency.id },
    { label: "Name", content: travelAgency.name },
    { label: "Email", content: travelAgency.email },
    {
      label: "Phone Number",
      content: travelAgency?.phoneNumber || (
        <span className="font-semibold text-red-500">
          Fill this to be able to create orders
        </span>
      ),
    },
    {
      label: "Funding Type",
      content: travelAgency.fundingType,
    },
    { label: "NIP", content: travelAgency.nip },
    { label: "Street", content: travelAgency.street },
    { label: "City", content: travelAgency.city },
    { label: "Zip code", content: travelAgency.zipCode },
    {
      label: "Offer Bundle ID",
      content: (
        <Link
          to={routes.travelAgencies.offerBundle.show.url(
            travelAgency.offerBundleId,
          )}
          className={"font-semibold"}
        >
          {travelAgency.offerBundleName}
        </Link>
      ),
    },
    { label: "Balance (AED)", content: travelAgency.balanceAed || "N/A" },
    { label: "Supervisor", content: travelAgency.supervisorName },
    {
      label: "Additional Details",
      content: travelAgency.additionalDetails || "N/A",
    },
  ];

  return (
    <>
      <DetailsTable>
        {details.map((detail) => (
          <DetailsTable.Row key={detail.label?.toString()} {...detail} />
        ))}
      </DetailsTable>
      <div className={"flex justify-end gap-4 mt-4"}>
        <UpdateTravelAgencyModal travelAgency={travelAgency} />
        <AddTravelAgencyBalanceModal travelAgencyId={travelAgency.id} />
      </div>
    </>
  );
};
