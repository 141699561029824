import { useMutation } from "@tanstack/react-query";
import { components } from "@twocontinents/shared";

import { toast, usePrivateApiClient } from "../utils";
import { useInvalidateGetOrder } from "./update-order";

export type ConfirmPaymentRequest =
  components["schemas"]["ConfirmPaymentRequest"];

export const useConfirmPayment = (id: number) => {
  const apiClient = usePrivateApiClient();
  const invalidateGetOrder = useInvalidateGetOrder();

  const { mutate, isPending } = useMutation({
    mutationFn: (request: ConfirmPaymentRequest) => {
      return apiClient.POST("/private/payment/confirm", {
        body: request,
      });
    },
    onSuccess: async () => {
      await invalidateGetOrder(id);
      toast.success("Payment confirmed.");
    },
    onError: () => {
      toast.error("Error while confirming payment.");
    },
  });

  return {
    confirmPayment: mutate,
    isPending,
  };
};
