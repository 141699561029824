import {
  Button,
  CurrencyCodeSelect,
  DateRangePicker,
  Form,
  FormItem,
  Input,
  Label,
  SettingCard,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode, DateFormatter } from "@twocontinents/shared";
import { DateRange } from "react-day-picker";

import { ApartmentCalendar } from "./apartment-calendar";
import { useCalendarSettings } from "./use-calendar-settings";

interface CalendarSettingsProps {
  apartmentId: number;
  managedInternally: boolean;
}

export const CalendarSettings = ({
  apartmentId,
  managedInternally,
}: CalendarSettingsProps) => {
  const {
    form,
    formattedDateTo,
    month,
    setMonth,
    calendar,
    formattedDateFrom,
    handleRangeChange,
    pricesPlaceholder,
    maximumStayPlaceholder,
    minimumStayPlaceholder,
    setAvailable,
    setNotAvailable,
    available,
    onSubmit,
    isPending,
    valid,
  } = useCalendarSettings(apartmentId);
  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Calendar settings</SettingCard.Title>
        <SettingCard.Description>
          Manage your calendar settings
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="available"
              render={() => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-32">
                      Available
                    </FormItem.Label>
                    <FormItem.Control>
                      <div className="flex gap-2">
                        <Button
                          disabled={!managedInternally}
                          variant="positive"
                          className={
                            available ? "" : "bg-gray-600 hover:bg-gray-600"
                          }
                          onClick={setAvailable}
                          type="button"
                        >
                          Available
                        </Button>
                        <Button
                          disabled={!managedInternally}
                          variant="destructive"
                          className={
                            available ? "bg-gray-600 hover:bg-gray-600" : ""
                          }
                          onClick={setNotAvailable}
                          type="button"
                        >
                          Not available
                        </Button>
                      </div>
                    </FormItem.Control>
                  </FormItem.Row>
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="price"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-32">Price</FormItem.Label>
                    <FormItem.Control>
                      <Input
                        disabled={!managedInternally}
                        placeholder={pricesPlaceholder}
                        {...field}
                        type={"number"}
                        className="max-w-md"
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="currency"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-32">
                      Currency
                    </FormItem.Label>
                    <FormItem.Control>
                      <CurrencyCodeSelect
                        disabled={!managedInternally}
                        value={field.value}
                        currencies={Object.values(CurrencyCode)}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="minimumStay"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-32">
                      Minimum stay
                    </FormItem.Label>
                    <FormItem.Control>
                      <Input
                        disabled={!managedInternally}
                        placeholder={minimumStayPlaceholder}
                        {...field}
                        type={"number"}
                        className="max-w-md"
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="maximumStay"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-32">
                      Maximum stay
                    </FormItem.Label>
                    <FormItem.Control>
                      <Input
                        disabled={!managedInternally}
                        placeholder={maximumStayPlaceholder}
                        {...field}
                        type={"number"}
                        className="max-w-md"
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />

            <div className="w-full flex justify-center">
              <ApartmentCalendar
                dateFrom={formattedDateFrom}
                dateTo={formattedDateTo}
                onChange={(e) => handleRangeChange(e as DateRange)}
                calendarEntries={calendar?.entries ?? []}
                month={month}
                onMonthChange={setMonth}
              />
            </div>
          </Form.Fields>
          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will change apartment calendar
            </SettingCard.Description>
            <SettingCard.Button
              isLoading={isPending}
              disabled={!managedInternally || !valid}
            >
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
