import {
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { useDefaultStaysAmountSettings } from "./use-default-stays-amount-settings";

interface DefaultStaysAmountSettingsProps {
  apartmentId: number;
  currentMinimumStay: number;
  currentMaximumStay: number;
  managedInternally: boolean;
}

export const DefaultStaysAmountSettings = ({
  apartmentId,
  currentMinimumStay,
  currentMaximumStay,
  managedInternally,
}: DefaultStaysAmountSettingsProps) => {
  const { form, onSubmit, isPending } = useDefaultStaysAmountSettings(
    apartmentId,
    currentMinimumStay,
    currentMaximumStay,
  );

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Default stays amount settings</SettingCard.Title>
        <SettingCard.Description>
          Change apartment's default stays amount settings
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="defaultMinimumStay"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Default minimum stay</FormItem.Label>
                    <FormItem.Control>
                      <Input
                        disabled={!managedInternally}
                        {...field}
                        type={"number"}
                        className="max-w-md"
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="defaultMaximumStay"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Default maximum stay</FormItem.Label>
                    <FormItem.Control>
                      <Input
                        disabled={!managedInternally}
                        {...field}
                        type={"number"}
                        className="max-w-md"
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
          </Form.Fields>
          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will change default minimum and maximum stays amount
            </SettingCard.Description>
            <SettingCard.Button
              isLoading={isPending}
              disabled={!managedInternally}
            >
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
