import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import * as React from "react";

import { cn } from "../../utils";
import { Button, Calendar, Popover } from "../shadcn";
import { useDateRangePicker } from "./use-date-range-picker";

dayjs.extend(minMax);

export function DateRangePicker({
  className,
  maxDate,
  minDate,
  notAvailableDates,
  setDateFrom,
  setDateTo,
  dateFrom,
  dateTo,
  month,
  onMonthChange,
}: React.HTMLAttributes<HTMLDivElement> & {
  maxDate?: Date;
  minDate?: Date;
  notAvailableDates?: Date[];
  dateFrom?: Date;
  dateTo?: Date;
  month?: Date;
  onMonthChange?: (date: Date) => void;
  setDateFrom: (date: Date | undefined) => void;
  setDateTo: (date: Date | undefined) => void;
}) {
  const {
    calendarOpened,
    calendarRef,
    onSetDateRange,
    toggleCalendar,
    buttonText,
  } = useDateRangePicker({
    dateFrom,
    dateTo,
    setDateFrom,
    setDateTo,
  });

  const dateRangeObject = {
    from: dateFrom,
    to: dateTo,
  };

  return (
    <div className={cn("flex justify-end w-full ", className)}>
      <Popover open={calendarOpened}>
        <Popover.Trigger asChild>
          <Button className={"w-full"} onClick={toggleCalendar}>
            {buttonText}
          </Button>
        </Popover.Trigger>
        <Popover.Content
          className="w-auto p-0 border-slate-100!"
          align="center"
          ref={calendarRef}
        >
          <Calendar
            month={month}
            onMonthChange={onMonthChange}
            initialFocus
            mode="range"
            disabled={notAvailableDates}
            defaultMonth={dateFrom}
            fromDate={minDate}
            toDate={maxDate}
            selected={dateRangeObject}
            onSelect={onSetDateRange}
            numberOfMonths={2}
            classNames={{
              day: "m-0.5",
              day_selected: "!bg-blue-600 aria-selected:!bg-blue-600",
            }}
          />
        </Popover.Content>
      </Popover>
    </div>
  );
}
