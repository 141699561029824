/* eslint-disable @typescript-eslint/naming-convention */
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/pl";
import "dayjs/locale/ro";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

const DEFAULT_LOCALE = "pl";

dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(duration);

dayjs.locale(DEFAULT_LOCALE);

export type DateType = string | number | dayjs.Dayjs | Date | null | undefined;

const formatToMMMMDYYYY = (date: DateType) => {
  return dayjs(date).format("MMMM D, YYYY");
};

const formatToMMMDD = (date: DateType, locale?: string) => {
  return dayjs(date)
    .locale(locale ?? DEFAULT_LOCALE)
    .format("MMM DD");
};

const formatToMMDDYYYY = (date: DateType, locale?: string) => {
  return dayjs(date)
    .locale(locale ?? DEFAULT_LOCALE)
    .format("L");
};

const formatToDMMMMYYYY = (date: DateType, locale?: string) => {
  return dayjs(date)
    .locale(locale ?? DEFAULT_LOCALE)
    .format("D MMMM, YYYY");
};

const formatToMMMMYYYY = (date: DateType, locale?: string) => {
  return dayjs(date)
    .locale(locale ?? DEFAULT_LOCALE)
    .format("MMMM YYYY");
};

const formatToDDMMYYYYHHmm = (date: DateType) => {
  return dayjs(date).format("DD.MM.YYYY, HH:mm");
};

const formatToDDMMTYYYYTHHmm = (date: DateType) => {
  return dayjs(date).format("YYYY-MM-DDTHH:mm");
};

const formatToYYYYMMDD = (date: DateType, locale?: string) => {
  return dayjs(date)
    .locale(locale ?? DEFAULT_LOCALE)
    .format("YYYY-MM-DD");
};

const formatTohmmA = (time: DateType) => {
  return dayjs(time, "HH:mm").format("h:mm A");
};

const formatToHHmm = (time: DateType, locale?: string) => {
  return dayjs(time, "HH:mm")
    .locale(locale ?? DEFAULT_LOCALE)
    .format("LT");
};

const formatToYYYYMMDDHHmmss = (date: DateType, time: string) => {
  return dayjs(`${date} ${time}`).format("YYYY-MM-DDTHH:mm:ss");
};

const formatToHHmmss = (time: DateType) => {
  return dayjs(time, "HH:mm:ss").format("HH:mm:ss");
};

const formatToHHmmssWithSecondsAndMinutesAs0 = (time: DateType) => {
  return dayjs(time, "HH:mm:ss").format("HH:00:00");
};

const formatToDDMMYYYY = (date: DateType) => {
  return dayjs(date).format("DD/MM/YYYY");
};

const formatDateTimeToDDMMYYYYHHmm = (date: DateType, time?: string) => {
  return `${formatToDDMMYYYY(date)}${time ? ", " + formatToHHmm(time) : ""}`;
};

const formatToNativeDate = (date: DateType) => {
  return dayjs(date).toDate();
};

const formatToZonedDateTime = (date: DateType) => {
  return dayjs(date).toISOString();
};

const formatToL = (
  date: DateType,
  { format, locale }: { format?: string; locale?: string } = {},
) => {
  return dayjs(date, format)
    .locale(locale ?? DEFAULT_LOCALE)
    .format("L");
};

const formatToLLL = (
  date: DateType,
  { format, locale }: { format?: string; locale?: string } = {},
) => {
  return dayjs(date, format)
    .locale(locale ?? DEFAULT_LOCALE)
    .format("LLL");
};
const formatToLL = (
  date: DateType,
  { format, locale }: { format?: string; locale?: string } = {},
) => {
  return dayjs(date, format)
    .locale(locale ?? DEFAULT_LOCALE)
    .format("LL");
};

const formatToLT = (
  date: DateType,
  { format, locale }: { format?: string; locale?: string } = {},
) => {
  return dayjs(date, format)
    .locale(locale ?? DEFAULT_LOCALE)
    .format("LT");
};

const formatTimeToHM = (timeInMs: number) => {
  return dayjs.duration(timeInMs).format("H[h] m[m]");
};

export const DateFormatter = {
  formatToMMMMDYYYY,
  formatToDMMMMYYYY,
  formatToMMMMYYYY,
  formatToDDMMYYYYHHmm,
  formatToDDMMTYYYYTHHmm,
  formatToYYYYMMDD,
  formatToHHmm,
  formatToDDMMYYYY,
  formatToMMMDD,
  formatToMMDDYYYY,
  formatToHHmmss,
  formatToHHmmssWithSecondsAndMinutesAs0,
  formatTohmmA,
  formatDateTimeToDDMMYYYYHHmm,
  formatToNativeDate,
  formatToZonedDateTime,
  formatToL,
  formatToLLL,
  formatToLT,
  formatToLL,
  formatToYYYYMMDDHHmmss,
  formatTimeToHM,
};
