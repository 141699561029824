import { ApiError, ApplicationErrorCode } from "@twocontinents/shared";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { useConfirmPayment } from "../../../../data-access";
import { useOpened } from "../../../../hooks";
import { toast } from "../../../../utils";
import { OrderStatusesFormValues } from "../use-order-statuses-form";

const CONFIRM_SENTENCE = "I want to confirm that payment";

export const useConfirmPaymentAlertDialog = (orderId: number) => {
  const { opened, open, close, toggle } = useOpened(false);

  const { setValue } = useFormContext<OrderStatusesFormValues>();

  const { confirmPayment, isPending } = useConfirmPayment(orderId);

  const [sentence, setSentence] = useState("");

  const handleConfirmPayment = () => {
    if (sentence === CONFIRM_SENTENCE) {
      confirmPayment(
        { orderId },
        {
          onSuccess: () => {
            close();
            setValue("paymentConfirmed", true);
          },
          onError: (error) => {
            const apiError = error as unknown as ApiError;

            if (
              apiError.applicationErrorCode ===
              ApplicationErrorCode.RESERVATION_NOT_ENOUGH_SLOTS
            ) {
              toast.error("Variant doesn't have enough slots.");
            } else {
              toast.error("Error while confirming payment.");
            }
          },
        },
      );
    }
  };

  const handleSentenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSentence(e.target.value);
  };

  const buttonDisabled = sentence !== CONFIRM_SENTENCE;

  return {
    opened,
    open,
    close,
    toggle,
    handleConfirmPayment,
    handleSentenceChange,
    buttonDisabled,
    isPending,
    CONFIRM_SENTENCE,
  };
};
