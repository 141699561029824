import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type Body = components["schemas"]["UpdateApartmentCalendarRequest"];

interface Props {
  apartmentId: number;
  body: Body;
}

export const useUpdateApartmentCalendar = () => {
  const apiClient = usePrivateApiClient();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ apartmentId, body }: Props) =>
      apiClient
        .PATCH(`/private/stays/apartments/{id}/calendar`, {
          params: {
            path: { id: apartmentId },
          },
          body,
        })
        .then((response) => response.data),

    onSuccess: () => {
      toast.success("Apartment calendar has been updated successfully.");
    },
    onError: () => {
      toast.error("Apartment calendar could not be updated");
    },
  });

  return { updateCalendar: mutate, isPending };
};
