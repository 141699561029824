import { OrderStatus } from "@twocontinents/dashboard/shared";
import { Layout, Tabs } from "@twocontinents/dashboard/shared";
import { useEffect } from "react";

import { useBookingOrderFiltersQueryParams } from "../hooks";
import { BookingsTab } from "./bookings-tab";

export const BookingOrdersIndexPage = () => {
  const { query, setQuery } = useBookingOrderFiltersQueryParams();

  const orderStatus = query.status ?? OrderStatus.NEW;

  function setStatus(status: OrderStatus | undefined) {
    return () => {
      setQuery({ status });
    };
  }

  useEffect(() => {
    setQuery({ status: OrderStatus.NEW });
  }, []);

  return (
    <Layout
      header={{
        title: "Orders",
        description: `Manage your booking orders.`,
      }}
    >
      <Tabs defaultValue={orderStatus} className="w-full">
        <Tabs.List className="mt-5 flex flex-col gap-y-2 min-[830px]:grid  w-full h-min min-[830px]:h-14  min-[830px]:grid-cols-3  p-2 ">
          <Tabs.Trigger
            className="h-full w-full min-[830px]:w-auto  py-5 min-[830px]:py-0 "
            value={OrderStatus.NEW}
            onClick={setStatus(OrderStatus.NEW)}
          >
            New
          </Tabs.Trigger>
          <Tabs.Trigger
            className="h-full w-full min-[830px]:w-auto py-5 min-[830px]:py-0 "
            value={OrderStatus.COMPLETED}
            onClick={setStatus(OrderStatus.COMPLETED)}
          >
            Completed
          </Tabs.Trigger>
          <Tabs.Trigger
            className="h-full w-full min-[830px]:w-auto py-5 min-[830px]:py-0 "
            value={"ALL"}
            onClick={setStatus(undefined)}
          >
            All
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value={OrderStatus.NEW}>
          <BookingsTab />
        </Tabs.Content>
        <Tabs.Content value={OrderStatus.COMPLETED}>
          <BookingsTab />
        </Tabs.Content>
        <Tabs.Content value={"ALL"}>
          <BookingsTab />
        </Tabs.Content>
      </Tabs>
    </Layout>
  );
};
