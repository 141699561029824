import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateApartment } from "../../data-access";

const InternalNumberSettingsSchema = z.object({
  internalNumber: z.string().min(3),
});

type InternalNumberSettingsSchema = z.infer<
  typeof InternalNumberSettingsSchema
>;

export const useInternalNumberSettings = (
  apartmentId: number,
  defaultInternalNumber: string,
) => {
  const { updateApartment, isPending } = useUpdateApartment();

  const form = useForm<InternalNumberSettingsSchema>({
    resolver: zodResolver(InternalNumberSettingsSchema),
    defaultValues: {
      internalNumber: defaultInternalNumber,
    },
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit((body) => {
    updateApartment({
      apartmentId,
      body,
    });
  });

  return {
    form,
    onSubmit,
    isPending,
  };
};
