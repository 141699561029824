import { usePaginationQueryParams } from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import { ChangeEvent } from "react";
import {
  DateParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

const params = {
  orderId: withDefault(NumberParam, undefined),
  customerName: withDefault(StringParam, undefined),
  customerSurname: withDefault(StringParam, undefined),
  tourDate: withDefault(DateParam, undefined),
  tourDateFrom: withDefault(DateParam, undefined),
  tourDateTo: withDefault(DateParam, undefined),
  attractionId: withDefault(NumberParam, undefined),
};

export const useTicketsFiltersQueryParams = () => {
  const [query, setQuery] = useQueryParams(params);

  const { page, size } = usePaginationQueryParams();

  const handleTextChange =
    (fieldName: StringTypeKey) => (e: ChangeEvent<HTMLInputElement>) => {
      setQuery({ [fieldName]: e.target.value });
    };

  const handleNumberChange =
    (fieldName: NumberTypeKey) => (e: ChangeEvent<HTMLInputElement>) => {
      const value = Number.isNaN(e.target.valueAsNumber)
        ? undefined
        : e.target.valueAsNumber;

      setQuery({
        [fieldName]: value,
      });
    };

  const handleDateFieldChange =
    (fieldName: DateTypeKey) => (date: Date | undefined) => {
      setQuery({
        [fieldName]: date,
      });
    };

  return {
    query: {
      ...query,
      page,
      size,
    },
    setQuery,
    handleTextChange,
    handleNumberChange,
    handleDateFieldChange,
  };
};

export type TicketsFiltersQueryParams = typeof params;

export type NumberTypeKey = {
  [K in keyof TicketsFiltersQueryParams]: TicketsFiltersQueryParams[K] extends typeof NumberParam
    ? K
    : never;
}[keyof TicketsFiltersQueryParams];

export type StringTypeKey = {
  [K in keyof TicketsFiltersQueryParams]: TicketsFiltersQueryParams[K] extends typeof StringParam
    ? K
    : never;
}[keyof TicketsFiltersQueryParams];

export type DateTypeKey = {
  [K in keyof TicketsFiltersQueryParams]: TicketsFiltersQueryParams[K] extends typeof DateParam
    ? K
    : never;
}[keyof TicketsFiltersQueryParams];
