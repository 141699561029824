import { CurrencyCode } from "@twocontinents/shared";

import { Select } from "./shadcn";

interface CurrencyCodeSelectProps {
  currencies: CurrencyCode[];
  defaultValue?: CurrencyCode;
  onValueChange: (CurrencyCode?: CurrencyCode) => void;
  defaultOpen?: boolean;
  value?: CurrencyCode;
  disabled?: boolean;
}

export const CurrencyCodeSelect = ({
  onValueChange,
  currencies,
  defaultOpen,
  defaultValue,
  value,
  disabled = false,
}: CurrencyCodeSelectProps) => {
  return (
    <Select
      disabled={disabled}
      defaultValue={defaultValue}
      onValueChange={(value) => onValueChange(value as CurrencyCode)}
      defaultOpen={defaultOpen}
      value={value}
    >
      <Select.Trigger className="max-w-md h-max min-h-10">
        <Select.Value placeholder="Select currency" />
      </Select.Trigger>
      <Select.Content>
        {currencies.map((currency) => (
          <Select.Item value={currency} key={currency}>
            {currency}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  );
};
