import { useGetAttractions } from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import { useBoolean } from "usehooks-ts";

import { useGetTickets } from "../data-access";
import { useTicketsFiltersQueryParams } from "../hooks";

export const useTicketsHistory = () => {
  const {
    query,
    handleTextChange,
    handleNumberChange,
    handleDateFieldChange,
    setQuery,
  } = useTicketsFiltersQueryParams();
  const { tickets, pagination } = useGetTickets({
    ...query,
    tourDate:
      query.tourDate && DateFormatter.formatToYYYYMMDD(query.tourDateFrom),
    tourDateTo:
      query.tourDateTo && DateFormatter.formatToYYYYMMDD(query.tourDateTo),
    tourDateFrom:
      query.tourDateFrom && DateFormatter.formatToYYYYMMDD(query.tourDateFrom),
  });

  const { attractions } = useGetAttractions();

  const attractionsOptions =
    attractions?.map((attraction) => ({
      description: attraction.description,
      id: attraction.id,
    })) ?? [];

  const { value: showRangePicker, toggle: toggleShowRangePicker } =
    useBoolean(false);

  const toggleRangePicker = () => {
    toggleShowRangePicker();
    setQuery({
      tourDate: undefined,
      tourDateFrom: undefined,
      tourDateTo: undefined,
    });
  };

  return {
    query,
    handleTextChange,
    handleNumberChange,
    handleDateFieldChange,
    setQuery,
    tickets,
    pagination,
    attractionsOptions,
    showRangePicker,
    toggleRangePicker,
    tourDateFrom: query.tourDateFrom
      ? DateFormatter.formatToYYYYMMDD(query.tourDateFrom)
      : undefined,
    tourDateTo: query.tourDateTo
      ? DateFormatter.formatToYYYYMMDD(query.tourDateTo)
      : undefined,
    tourDate: query.tourDate
      ? DateFormatter.formatToYYYYMMDD(query.tourDate)
      : undefined,
  };
};
