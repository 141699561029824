import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

import { GetOrdersFilters } from "../types";

export const useGetOrders = (filters?: GetOrdersFilters) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/orders/attraction/", {
          params: {
            query: filters,
          },
        })
        .then((response) => response.data),
    queryKey: ["private-attraction-orders", filters ?? "all"],
    refetchOnWindowFocus: true,
  });

  return {
    paginatedOrders: data,
    arePaginatedOrdersLoading: isLoading,
  };
};

export const useInvalidateGetOrders = () => {
  const queryClient = useQueryClient();

  const invalidate = async () => {
    await queryClient.invalidateQueries({
      queryKey: ["private-attraction-orders"],
    });
  };

  return {
    invalidate,
  };
};
