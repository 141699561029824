import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ALL_ITEMS_ID,
  NOT_SELECTED_ID,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";

const queryKey = (apartmentId: number, dateFrom?: string, dateTo?: string) => {
  const dateKey = dateFrom && dateTo ? [dateFrom, dateTo] : [];
  return ["apartment-calendar", apartmentId, ...dateKey];
};

export const useGetApartmentCalendar = (
  apartmentId: number,
  dateFrom: string,
  dateTo: string,
) => {
  const apiClient = usePrivateApiClient();
  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET(`/private/stays/apartments/{id}/calendar`, {
          params: {
            path: { id: apartmentId },
            query: {
              dateFrom,
              dateTo,
            },
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(apartmentId, dateFrom, dateTo),
    enabled: apartmentId !== NOT_SELECTED_ID && apartmentId !== ALL_ITEMS_ID,
  });

  return { calendar: data, isLoading };
};

export const useInvalidateGetApartmentCalendar = () => {
  const queryClient = useQueryClient();

  const invalidate = async (
    apartmentId: number,
    dateFrom?: string,
    dateTo?: string,
  ) => {
    await queryClient.invalidateQueries({
      exact: false,
      queryKey: queryKey(apartmentId, dateFrom, dateTo),
    });
  };

  return { invalidate };
};
