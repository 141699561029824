import {
  ApiLocaleSelect,
  Input,
  Label,
  PaymentStatusFilter,
  Select,
} from "@twocontinents/dashboard/shared";

import { useFilterBar } from "./use-filter-bar";

export const FilterBar = () => {
  const { handleTextChange, query, setQuery } = useFilterBar();

  return (
    <div className="flex flex-col mt-4">
      <div className="gap-5 flex  items-start border-slate-700 flex-wrap">
        <div className="flex  gap-4"></div>
        <div className="flex gap-4 flex-wrap">
          <Input
            name="firstName"
            className="w-44"
            placeholder="Jan"
            value={query.customerName}
            onChange={handleTextChange("customerName")}
          />
          <Input
            name="lastName"
            className="w-44"
            placeholder="Kowalski"
            value={query.customerSurname}
            onChange={handleTextChange("customerSurname")}
          />
          <Input
            name="email"
            className="w-44"
            placeholder="Email"
            value={query.customerEmail}
            onChange={handleTextChange("customerEmail")}
          />
          <Input
            name="id"
            className="w-44"
            placeholder="Order ID"
            type="number"
            value={query.bookingId}
            onChange={handleTextChange("bookingId")}
          />
        </div>
      </div>
      <div className="flex gap-8 items-center my-4">
        <div className="w-fit  justify-start flex flex-col gap-2">
          <Label>Payment status</Label>
          <Select>
            <Select.Trigger className="w-44">
              <Select.Value placeholder="Wszystkie" />
            </Select.Trigger>
            <Select.Content>
              <Select.Item value={PaymentStatusFilter.ALL}>All</Select.Item>
              <Select.Item value={PaymentStatusFilter.CONFIRMED}>
                Only Paid
              </Select.Item>
              <Select.Item value={PaymentStatusFilter.NOT_CONFIRMED}>
                Only Not Paid
              </Select.Item>
            </Select.Content>
          </Select>
        </div>

        <div className="flex flex-col gap-2"></div>
        <div>
          <Label>Locale</Label>
          <ApiLocaleSelect
            value={query.locale}
            onValueChange={(locale) => setQuery({ locale })}
            reset={() => setQuery({ locale: undefined })}
          />
        </div>
      </div>
    </div>
  );
};
