import { Layout } from "@twocontinents/dashboard/shared";
import { useParams } from "react-router-dom";

import { useGetApartment } from "../data-access";
import { ActivitySettings } from "./activity-settings";
import { CalendarSettings } from "./calendar-settings";
import { DefaultPriceSettings } from "./default-price-settings";
import { DefaultStaysAmountSettings } from "./default-stays-amount-settings";
import { DescriptionSettings } from "./description-settings";
import { InternalNumberSettings } from "./internal-number-settings";
import { MaxGuestsAmountSettings } from "./max-guests-amount-settings";

export const ApartmentShowPage = () => {
  const params = useParams<{ id: string }>();
  const id = Number(params.id);

  const { apartment, isLoading } = useGetApartment(id);

  if (isLoading || !apartment) {
    return (
      <Layout
        header={{
          title: `Manage Apartment ...`,
          description: "Browse and manage apartments",
        }}
      ></Layout>
    );
  }

  return (
    <Layout
      header={{
        title: `Manage Apartment: ${apartment.id}. ${apartment.description} (${apartment.internalNumber})`,
        description: "Browse and manage apartment",
      }}
    >
      <Layout.Content>
        <ActivitySettings
          apartmentId={apartment.id}
          defaultActive={apartment.active}
        />
        <DescriptionSettings
          apartmentId={apartment.id}
          defaultDescription={apartment.description}
        />
        <InternalNumberSettings
          apartmentId={apartment.id}
          defaultInternalNumber={apartment.internalNumber}
        />
        <DefaultStaysAmountSettings
          apartmentId={apartment.id}
          currentMinimumStay={apartment.defaultMinimumStay}
          currentMaximumStay={apartment.defaultMaximumStay}
          managedInternally={apartment.managedInternally}
        />
        <MaxGuestsAmountSettings
          apartmentId={apartment.id}
          currentMaxGuestsAmount={apartment.maxGuestsAmount}
          currentMaxAdultsAmount={apartment.maxAdultsAmount}
          currentMaxAdditionalBedsAmount={apartment.maxAdditionalBedsAmount}
          managedInternally={apartment.managedInternally}
        />
        <DefaultPriceSettings
          apartmentId={apartment.id}
          currentDefaultPrice={apartment.defaultPrice}
          currentAdditionalBedPrice={apartment.additionalBedPrice}
          managedInternally={apartment.managedInternally}
        />
        <CalendarSettings
          apartmentId={apartment.id}
          managedInternally={apartment.managedInternally}
        />
      </Layout.Content>
    </Layout>
  );
};
