import { zodResolver } from "@hookform/resolvers/zod";
import { ActivityFilter } from "@twocontinents/dashboard/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useGetApartments } from "../../data-access";

const ApartmentsFiltersFormSchema = z.object({
  name: z.string().optional(),
  active: z.string().optional(),
});

export type ApartmentsFiltersFormSchema = z.infer<
  typeof ApartmentsFiltersFormSchema
>;

const matchesName = (name: string, search?: string) => {
  if (!search) return true;
  return name.toLowerCase().includes(search.toLowerCase());
};

const matchesActivityStatus = (active: boolean, activeFilter?: string) => {
  if (activeFilter === ActivityFilter.ALL) return true;
  const isActive = activeFilter === ActivityFilter.ACTIVE;
  return active === isActive;
};

export const useApartmentsListing = () => {
  const { apartments } = useGetApartments();

  const form = useForm<ApartmentsFiltersFormSchema>({
    resolver: zodResolver(ApartmentsFiltersFormSchema),
    defaultValues: {
      active: ActivityFilter.ALL,
    },
  });

  const name = form.watch("name");
  const active = form.watch("active");

  const matchingApartments =
    apartments?.filter((apartment) => {
      return (
        matchesName(apartment.description, name) &&
        matchesActivityStatus(apartment.active, active)
      );
    }) ?? [];

  const sortedApartments = matchingApartments.sort((a, b) => a.id - b.id);

  return {
    form,
    apartments: sortedApartments,
  };
};
