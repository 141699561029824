import { FinancialLogLevel } from "@twocontinents/dashboard/shared";

import { TourBase } from "../types";

export const checkIfTourHasPrimaryFinancialLog = <
  T extends TourBase["financialLogs"][number],
>(
  financialLogs: T[],
) => {
  return financialLogs.find(
    (log) => log.level === FinancialLogLevel.PRIMARY && log.plnValue < 0,
  );
};
