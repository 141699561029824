import { AlertDialog, Button, Input, buttonVariants } from "../../../shadcn";
import { useConfirmPaymentAlertDialog } from "./use-confirm-payment-alert-dialog";

interface ConfirmPaymentAlertDialogProps {
  orderId: number;
}

export const ConfirmPaymentAlertDialog = ({
  orderId,
}: ConfirmPaymentAlertDialogProps) => {
  const {
    opened,
    toggle,
    handleConfirmPayment,
    handleSentenceChange,
    buttonDisabled,
    isPending,
    CONFIRM_SENTENCE,
  } = useConfirmPaymentAlertDialog(orderId);

  return (
    <AlertDialog open={opened} onOpenChange={toggle}>
      <AlertDialog.Trigger asChild>
        <h3 className="text-sm cursor-pointer">Payment status</h3>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>
            Are you sure that you want to set payment as confirmed?{" "}
          </AlertDialog.Title>
          <AlertDialog.Description className="text-white">
            This action cannot be undone and the system administrator will be
            notified. To confirm, write below the following sentence{" "}
            <strong>”{CONFIRM_SENTENCE}”</strong> and click “Confirm”
          </AlertDialog.Description>
        </AlertDialog.Header>
        <div className="flex flex-col gap-6 mt-4">
          <Input
            type="text"
            name="confirmationSentence"
            autoComplete="off"
            onChange={handleSentenceChange}
          />
          <AlertDialog.Footer>
            <AlertDialog.Cancel
              type="button"
              className={buttonVariants({
                variant: "destructive",
                className: "border-none",
              })}
            >
              Cancel
            </AlertDialog.Cancel>
            <Button
              isLoading={isPending}
              disabled={buttonDisabled}
              type="button"
              onClick={handleConfirmPayment}
              variant="outline"
            >
              Submit
            </Button>
          </AlertDialog.Footer>
        </div>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
