import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { Apartment } from "@twocontinents/dashboard/stays/apartments/shared";

export const useGetApartments = (
  options?: Partial<UseQueryOptions<Apartment[], unknown>>,
) => {
  const apiClient = usePrivateApiClient();
  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/stays/apartments/")
        .then((res) => res?.data?.apartments ?? []),
    queryKey: ["apartments"],
    ...options,
  });

  return { apartments: data ?? [], isLoading };
};
