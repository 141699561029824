import { zodResolver } from "@hookform/resolvers/zod";
import { useGetAttractions, useOpened } from "@twocontinents/dashboard/shared";
import { ApiLocale, DateFormatter } from "@twocontinents/shared";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useCreateManualReview } from "../../data-access";

const AddManualReviewFormSchema = z.object({
  rating: z.coerce.number().step(0.5).min(1).max(5),
  content: z.string().min(3).max(500).optional(),
  attractionId: z.coerce.number(),
  customerName: z.string(),
  createdAtDate: z.string(),
  createdAtTime: z.string(),
  locale: z.nativeEnum(ApiLocale),
});

type AddManualReviewFormValues = z.infer<typeof AddManualReviewFormSchema>;

export const useAddManualReviewDialog = () => {
  const { opened, close, toggle } = useOpened(false);
  const { createManualReview, isPending } = useCreateManualReview();
  const [pictures, setPictures] = useState<File[]>([]);

  const form = useForm<AddManualReviewFormValues>({
    resolver: zodResolver(AddManualReviewFormSchema),
  });

  const createdAtTime = form.watch("createdAtTime");

  const { attractions } = useGetAttractions();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = () => {
    const files = fileInputRef.current?.files;
    if (files) {
      setPictures([...files]);
    }
  };

  const onSubmit = form.handleSubmit((data) => {
    const { createdAtDate, createdAtTime, ...rest } = data;
    createManualReview(
      {
        pictures,
        body: {
          ...rest,
          createdAt: DateFormatter.formatToDDMMTYYYYTHHmm(
            new Date(`${createdAtDate} ${createdAtTime}`),
          ),
        },
      },
      {
        onSuccess: () => {
          form.reset();
          setPictures([]);
          close();
        },
      },
    );
  });

  return {
    opened,
    form,
    onSubmit,
    isPending,
    pictures,
    attractions,
    createdAtTime,
    handleFileInputChange,
    fileInputRef,
    toggle,
  };
};
