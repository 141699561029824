import { SettingCard } from "../../setting-card";
import { Form } from "../../shadcn";
import { OrderStatusSwitch } from "./order-status-switch";
import { PaymentStatusSwitch } from "./payment-status-switch";
import {
  OrderStatusesFormProps,
  useOrderStatusesForm,
} from "./use-order-statuses-form";

export const OrderStatusesSwitches = ({ order }: OrderStatusesFormProps) => {
  const { form, onSubmit, isPending } = useOrderStatusesForm(order);

  return (
    <Form {...form}>
      <form onSubmit={onSubmit}>
        <div className="flex gap-10 mb-6 w-full justify-center ">
          <OrderStatusSwitch />
          <PaymentStatusSwitch orderId={order.id} />
        </div>

        <SettingCard.Footer>
          <SettingCard.Description>
            Order status will be changed after pressing save.
          </SettingCard.Description>
          <SettingCard.Button isLoading={isPending}>Save</SettingCard.Button>
        </SettingCard.Footer>
      </form>
    </Form>
  );
};
