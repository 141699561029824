import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { paths } from "@twocontinents/shared";

export type GetTicketsFilter = NonNullable<
  paths["/private/attractions/tickets/"]["get"]["parameters"]["query"]
>;

const queryKey = (filters?: GetTicketsFilter) => [
  "private-tickets",
  filters ?? "all",
];

export const useGetTickets = (filters?: GetTicketsFilter) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryKey: queryKey(filters),
    queryFn: () =>
      apiClient
        .GET("/private/attractions/tickets/", {
          params: {
            query: filters,
          },
        })
        .then((response) => response.data),
  });

  return {
    tickets: data?.tickets ?? [],
    pagination: data?.pagination,
    isLoading,
  };
};

export const useInvalidateGetTickets = () => {
  const queryClient = useQueryClient();

  const invalidate = async (filters?: GetTicketsFilter) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(filters),
    });
  };

  return {
    invalidate,
  };
};
