import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

import { GetBookingOrdersFilters } from "../types";

const queryKey = (filters?: GetBookingOrdersFilters) => [
  "private-booking-orders",
  filters ?? "all",
];

export const useGetBookingOrders = (filters?: GetBookingOrdersFilters) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/orders/booking/", {
          params: {
            query: filters,
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(filters),
    refetchOnWindowFocus: true,
  });

  return {
    paginatedOrders: data,
    isLoading,
  };
};

export const useInvalidateGetOrders = () => {
  const queryClient = useQueryClient();

  const invalidate = async () => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(undefined),
    });
  };

  return {
    invalidate,
  };
};
